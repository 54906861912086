"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGender = exports.isValidCheckDigits = exports.possibleBirthDateOfIdNumber = exports.idNumberContainsBirthDate = exports.possibleAgeOfPersonWithIdNumber = exports.possibleAgesOfPersonWithIdNumber = exports.validateNorwegianIdNumber = exports.Gender = exports.IDNumberType = exports.isValidDate = exports.diffYears = exports.NorwegianId = void 0;
/**
 * Object-oriented API for Norwegian National ID Validator
 * @example
 * ```javascript
 * import { NorwegianId } from 'norwegian-national-id-validator';
 *
 * const valid = NorwegianId('0000000000');
 * ```
 * @param idNumber norwegian social security number
 */
var NorwegianId = function (idNumber) {
    var valid = validateNorwegianIdNumber(idNumber);
    return {
        idNumber: idNumber,
        isValid: function () { return valid; },
        isBirthNumber: function () {
            return valid && idNumberType(idNumber) == IDNumberType.BirthNumber;
        },
        isDNumber: function () { return valid && idNumberType(idNumber) == IDNumberType.DNumber; },
        isHNumber: function () { return valid && idNumberType(idNumber) == IDNumberType.HNumber; },
        isFhNumber: function () { return valid && idNumberType(idNumber) == IDNumberType.FHNumber; },
        isMale: function () { return valid && getGender(idNumber) == Gender.Male; },
        isFemale: function () { return valid && getGender(idNumber) == Gender.Female; },
        age: function () { return possibleAgeOfPersonWithIdNumber(idNumber); },
        birthDate: function () {
            return (valid && possibleBirthDateOfIdNumber(idNumber)) || undefined;
        },
    };
};
exports.NorwegianId = NorwegianId;
/**
 * Calculated the difference betweeen two dates.
 * @param startDate Date instance
 * @param endDate Date instance
 * @private
 */
function diffYears(startDate, endDate) {
    var yStart = startDate.getFullYear();
    var mStart = startDate.getMonth();
    var dStart = startDate.getDate();
    var yEnd = endDate.getFullYear();
    var mEnd = endDate.getMonth();
    var dEnd = endDate.getDate();
    var diff = yStart - yEnd;
    if (mEnd > mStart || (mEnd === mStart && dEnd > dStart)) {
        return diff - 1;
    }
    return diff;
}
exports.diffYears = diffYears;
/**
 * Checks if a date is valid against another
 * @param date Date instance
 * @param expectedYear
 * @param expectedMonth
 * @param expectedDay
 */
function isValidDate(date, expectedYear, expectedMonth, expectedDay) {
    return (date.getFullYear() === Number(expectedYear) &&
        date.getMonth() + 1 === Number(expectedMonth) &&
        date.getDate() === Number(expectedDay));
}
exports.isValidDate = isValidDate;
/** In Norway there are several different ID numbers */
var IDNumberType;
(function (IDNumberType) {
    /**
     * A national identity number (birth number) is an ID number for you who
     * have a residence permit and are going to live in Norway for more than
     * six months.
     */
    IDNumberType[IDNumberType["BirthNumber"] = 0] = "BirthNumber";
    /**
     * A D number is a temporary identification number that you get if you have
     * applied for protection (asylum), or if you have a residence permit and
     * are going to stay in Norway for less than six months.
     */
    IDNumberType[IDNumberType["DNumber"] = 1] = "DNumber";
    /**
     * A H number is a number used for assistance, a unique identification of a
     * person that does not have a national ID or a D number or in cases where
     * this is not known. A H number contains information about age and gender.
     */
    IDNumberType[IDNumberType["HNumber"] = 2] = "HNumber";
    /**
     * A FH number is used in health care to uniquely identify patients that
     * does not have a known national ID or D number. A FH number does not have
     * any information about age or gender.
     */
    IDNumberType[IDNumberType["FHNumber"] = 3] = "FHNumber";
})(IDNumberType = exports.IDNumberType || (exports.IDNumberType = {}));
/**
 * Birth numbers, D-number and H-number contains information about gender
 */
var Gender;
(function (Gender) {
    /** If the third last digit in the ID number is odd, it is a male */
    Gender[Gender["Male"] = 0] = "Male";
    /** If the third last digit in the ID number is even, it is a female */
    Gender[Gender["Female"] = 1] = "Female";
})(Gender = exports.Gender || (exports.Gender = {}));
/**
 * Checks if the given value is a valid Norwegian national identity number.
 * @example
 * ```javascript
 * import { validateNorwegianIdNumber } from 'norwegian-national-id-validator';
 * const valid = validateNorwegianIdNumber(0000000000);
 * ```
 * @param idNumber social security number
 * @returns `true` for valid, and `false` for invalid ID number.
 */
function validateNorwegianIdNumber(idNumber) {
    var trimmed = idNumber.trim();
    if (isNaN(Number(trimmed)))
        return false;
    if (trimmed.length !== 11)
        return false;
    if (!isValidCheckDigits(trimmed))
        return false;
    var type = idNumberType(trimmed);
    if (type === IDNumberType.FHNumber)
        return true;
    else
        return possibleAgesOfPersonWithIdNumber(trimmed).length > 0;
}
exports.validateNorwegianIdNumber = validateNorwegianIdNumber;
/**
 * Find possible age of person based of ID number
 * @param elevenDigits Identification number
 */
function possibleAgesOfPersonWithIdNumber(elevenDigits) {
    var possibleAge = possibleAgeOfPersonWithIdNumber(elevenDigits);
    return possibleAge == null ? [] : [possibleAge];
}
exports.possibleAgesOfPersonWithIdNumber = possibleAgesOfPersonWithIdNumber;
/**
 * Returns the age of a person with given Norwegian national identity number.
 * Returns `undefined` when birth date could not be determined (e.g. for FH-numbers and invalid ID-numbers).
 * @param elevenDigits Identification number
 */
function possibleAgeOfPersonWithIdNumber(elevenDigits) {
    var birthDate = possibleBirthDateOfIdNumber(elevenDigits);
    if (birthDate == null) {
        return undefined;
    }
    var years = diffYears(new Date(), birthDate);
    return years >= 0 && years < 125 ? years : undefined;
}
exports.possibleAgeOfPersonWithIdNumber = possibleAgeOfPersonWithIdNumber;
/**
 * Check if idNumber contains birth date
 * @param elevenDigits idNumber
 */
function idNumberContainsBirthDate(elevenDigits) {
    return idNumberType(elevenDigits) !== IDNumberType.FHNumber;
}
exports.idNumberContainsBirthDate = idNumberContainsBirthDate;
/**
 * Get possible birth date from ID number
 * @param elevenDigits IdNumber
 */
function possibleBirthDateOfIdNumber(elevenDigits) {
    if (elevenDigits.length !== 11)
        return undefined;
    var type = idNumberType(elevenDigits);
    switch (type) {
        case IDNumberType.BirthNumber:
            return possibleBirthDateOfBirthNumber(elevenDigits);
        case IDNumberType.DNumber:
            return possibleBirthDateOfDNumber(elevenDigits);
        case IDNumberType.HNumber:
            return possibleBirthDateOfHNumber(elevenDigits);
    }
    return undefined;
}
exports.possibleBirthDateOfIdNumber = possibleBirthDateOfIdNumber;
/**
 * Get the ID number kind/type. This function does not validate, so
 * it should be combined with {@linkcode validateNorwegianIdNumber}.
 * @example
 * ```javascript
 * import { idNumberType, validateNorwegianIdNumber } from 'norwegian-national-id-validator';
 * if (validateNorwegianIdNumber(0000000000)) {
 *   const type = idNumberType(00000000000);
 * }
 * ```
 * @param elevenDigits IdNumber
 */
function idNumberType(elevenDigits) {
    var firstDigit = parseInt(elevenDigits[0]);
    if (firstDigit === 8 || firstDigit === 9)
        return IDNumberType.FHNumber;
    if (firstDigit >= 4 && firstDigit <= 7)
        return IDNumberType.DNumber;
    var thirdDigit = parseInt(elevenDigits[2]);
    if (thirdDigit === 4 || thirdDigit === 5)
        return IDNumberType.HNumber;
    else
        return IDNumberType.BirthNumber;
}
/**
 * Get possible birth date from BirthNumber
 * @param elevenDigits BirthNumber
 */
function possibleBirthDateOfBirthNumber(elevenDigits) {
    return getBirthDate(elevenDigits, IDNumberType.BirthNumber);
}
/**
 * Get possible birth date from HNumber
 * @param elevenDigits HNumber
 */
function possibleBirthDateOfHNumber(elevenDigits) {
    var correctedThirdDigit = (parseInt(elevenDigits[2]) - 4).toString();
    return getBirthDate(elevenDigits.slice(0, 2) + correctedThirdDigit + elevenDigits.slice(3, 11), IDNumberType.HNumber);
}
/**
 * Get possible birth date from DNumber
 * @param elevenDigits DNumber
 */
function possibleBirthDateOfDNumber(elevenDigits) {
    var correctedFirstDigit = (parseInt(elevenDigits[0]) - 4).toString();
    return getBirthDate(correctedFirstDigit + elevenDigits.slice(1, 11), IDNumberType.DNumber);
}
/**
 * @private
 */
function getBirthDate(elevenDigitsWithDDMMYY, idNumberType) {
    var DD = elevenDigitsWithDDMMYY.slice(0, 2);
    var MM = elevenDigitsWithDDMMYY.slice(2, 4);
    var YY = elevenDigitsWithDDMMYY.slice(4, 6);
    var YY_int = parseInt(YY);
    var ageGroupNumber = parseInt(elevenDigitsWithDDMMYY.slice(6, 9));
    var centuryPrefix = '20';
    if (ageGroupNumber >= 0 && ageGroupNumber < 500) {
        centuryPrefix = '19';
    }
    else if (idNumberType === IDNumberType.DNumber) {
        centuryPrefix = '20';
    }
    else if (ageGroupNumber >= 500 && ageGroupNumber < 750 && YY_int >= 54) {
        centuryPrefix = '18';
    }
    else if (ageGroupNumber >= 900 && ageGroupNumber < 1000 && YY_int >= 40) {
        centuryPrefix = '19';
    }
    var fullYear = "".concat(centuryPrefix).concat(YY);
    var isoStr = [fullYear, MM, DD].join('-') + 'T00:00:00';
    var birthDate = new Date(isoStr);
    if (!isValidDate(birthDate, fullYear, MM, DD)) {
        return undefined;
    }
    return birthDate;
}
/**
 * @private
 */
function isValidCheckDigits(elevenDigits) {
    var staticSequenceFirstCheckDigit = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1];
    var staticSequenceSecondCheckDigit = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    var elevenDigitsArray = elevenDigits.split('').map(Number);
    return (isValidCheckDigit(staticSequenceFirstCheckDigit, elevenDigitsArray) &&
        isValidCheckDigit(staticSequenceSecondCheckDigit, elevenDigitsArray));
}
exports.isValidCheckDigits = isValidCheckDigits;
/**
 * @private
 */
function isValidCheckDigit(staticSequence, elevenDigits) {
    var productSum = staticSequence.reduce(function (acc, value, index) { return acc + value * elevenDigits[index]; }, 0);
    return productSum % 11 === 0;
}
/**
 * Returns the gender based of ID number. Returns `undefined` when no gender
 * information is available.
 * @param elevenDigits ID number
 */
function getGender(elevenDigits) {
    if (elevenDigits.length != 11) {
        return undefined;
    }
    else if (idNumberType(elevenDigits) == IDNumberType.FHNumber) {
        return undefined;
    }
    var isFemale = Number(elevenDigits.charAt(8)) % 2 == 0;
    if (isFemale) {
        return Gender.Female;
    }
    else {
        return Gender.Male;
    }
}
exports.getGender = getGender;
